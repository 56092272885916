import React from "react";
import '../../../assets/css/fonts.css';
import '../../../assets/css/Contact_Headers.css';

export function ContactHeaders() {
    return (
        <div className="bg-image">
            <div className="content">
                <h1>SERVICES</h1>
                <p>WE ARE PROFESSIONALS IN THE FOLLOWING SERVICES</p>
            </div>
            
        </div>
    )
}